@tailwind base;
@tailwind components;
@tailwind utilities;

.without_scrollbar {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll; 
}
.without_scrollbar::-webkit-scrollbar {
    display: none;
}

.bg_blue_dots {
    background: url("https://cdn.fixat.mx/assets/invoicing/Path_gray.png") no-repeat right top 50%, linear-gradient( #146DBF, #146DBF);
}

.tooltip{
    width: 250px!important;
    max-width: 250px;
    white-space : normal;
}

.react-datepicker {
    border: 1px solid #FFF!important;
    border-radius: 20px!important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__header {
    background-color: #FFF!important;
    padding: 0.75rem!important;
    border: 1px solid #FFF;
    border-bottom: none!important;
}

.react-datepicker__current-month{
    color: rgb(17 24 39)!important;
    font-weight: 400!important;
}
.react-datepicker__current-year{
    color: rgb(17 24 39)!important;
    font-weight: 400!important;
}

.react-datepicker__navigation-icon::before{
    border-color: rgb(17 24 39)!important;
    margin-top: 12px!important;
}

.react-datepicker__navigation-icon:hover::before{
    border-color: #777!important;
}

.react-datepicker__day-name{
    color: rgb(17 24 39)!important;
}

.react-datepicker__day--selected{
    border-radius: 100%!important;
}

.react-datepicker__day:hover{
    border-radius: 100%!important;
}

.react-datepicker-year-header {
    color: rgb(17 24 39)!important;
    font-weight: 400!important;
}

.react-datepicker__month{
    margin: 0rem!important;
    padding: 0 1rem 1rem 1rem !important;
}
.react-datepicker__year{
    margin: 0rem!important;
    padding: 0 1rem 1rem 1rem !important;
}

.react-datepicker__month-wrapper{
    margin: 0.4rem!important
}

.react-datepicker__month-text{
    padding: 0.4rem 0 !important;
}
.react-datepicker__year-text{
    padding: 0.4rem 0 !important;
}
.react-datepicker__year .react-datepicker__year-text{
    width: 3.5rem !important;
}
.react-datepicker__month-text--selected{
    background-color: #146DBF!important;
}
.react-datepicker__year-text--selected{
    background-color: #146DBF!important;
}

.DatePicker__input {
    text-align: left!important;
    padding: 0.625rem 0.5rem!important;
    font-size: 1rem!important;
}

@media (max-width: 1024px) {
    .bg_blue_dots {
        background: #146DBF;
    }
}